import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"

const Layout = ({ pageTitle, children }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    const AddRemoveScrollToTopButton = () => {
      //if (pageTitle.includes('ontact')) return
      const main = document.getElementsByTagName('main')[0]
      const scrollUpButtons = document.getElementsByClassName('scroll-up-button')
      if (main.clientHeight > window.innerHeight && !pageTitle.includes('ontact')) {
        if (scrollUpButtons.length < 1) {
          const scrollUpButton = document.createElement('button')
          scrollUpButton.classList.add('scroll-up-button')
          scrollUpButton.onclick = () => window.scrollTo(0,0)
          const footer = document.getElementsByTagName('footer')[0]
          footer.appendChild(scrollUpButton)
        }
      } else {
        if (scrollUpButtons.length > 0) {
          for (let i = 0; i < scrollUpButtons.length; i++) {
            scrollUpButtons[i].parentNode.removeChild(scrollUpButtons[i])  
          }
        }
      }
    }

    AddRemoveScrollToTopButton()
    const main = document.getElementsByTagName('main')[0]
    main.addEventListener('resize', AddRemoveScrollToTopButton)
    window.addEventListener('resize', AddRemoveScrollToTopButton)
    return () => main.removeEventListener('resize', AddRemoveScrollToTopButton)
  }, [pageTitle])
    
  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div 
        style={{
          margin: `0 auto`,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main >
          {children}
        </main>
        <footer style={{ textAlign: `center`, marginTop: `80px`, }}></footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
